<template>
  <div
    ref="modalBody"
    class="relative z-[120] size-full overflow-y-auto border border-black bg-white drop-shadow-lg lg:z-30 lg:h-auto lg:max-h-[70vh] lg:w-3/5 2xl:w-1/2"
  >
    <div class="flex items-center justify-between bg-g7-blue">
      <CloudinaryImg
        public-id="go7seas-logo"
        :steps="[200, 400, 600]"
        class="my-4 ml-4 w-40"
      />
      <div
        class="mr-4 flex size-10 rotate-45 cursor-pointer items-center justify-center rounded-full bg-white"
        @click="emit('toggle')"
      >
        <IconsPlus class="size-8 fill-g7-blue" />
      </div>
    </div>
    <slot />
  </div>
</template>
<script lang="ts" setup>
const emit = defineEmits(["toggle"]);
const modalBody = ref();

defineExpose({
  modalBody,
});
</script>
