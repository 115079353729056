<template>
  <ModalBackground @click="onClickOutside">
    <ModalBody ref="ratingModal" @toggle="emit('toggleModal')">
      <Headline class="m-4" :title="item.title" />

      <Bar :title="item.publish_name" />

      <div class="p-4 uppercase text-g7-blue">
        <div :class="classes">
          <label class="font-bold"> {{ item.age_group?.label }}: </label>
          <span class="sm:font-bold"> {{ item.age_group?.value }}</span>
        </div>
        <div :class="classes">
          <label class="font-bold"> {{ item.no_of_trips?.label }}: </label>
          <span class="sm:font-bold"> {{ item.no_of_trips?.value }}</span>
        </div>
        <div :class="classes">
          <label class="font-bold"> {{ item.cruise_type?.label }}: </label>
          <span class="sm:font-bold"> {{ item.cruise_type?.value }}</span>
        </div>
        <div :class="classes">
          <label class="font-bold"> {{ item.cabin_type_class?.label }}: </label>
          <span class="sm:font-bold"> {{ item.cabin_type_class?.value }}</span>
        </div>
        <div :class="classes">
          <label class="font-bold"> {{ item.cruise_region?.label }}: </label>
          <span class="sm:font-bold"> {{ item.cruise_region?.value }}</span>
        </div>
        <div :class="classes">
          <label class="font-bold"> {{ item.cruise_date?.label }}: </label>
          <span class="sm:font-bold"> {{ item.cruise_date?.value }}</span>
        </div>
      </div>
      <Bar title="Gesamte Bewertung" />
      <div v-for="(review, index) in item.review_categories" :key="index">
        <Mygo7seasShipRatingDetails class="p-4" :review="review" />
        <hr class="border" />
      </div>
    </ModalBody>
  </ModalBackground>
</template>
<script lang="ts" setup>
import type { ShipRatingDetails } from "~/types/profile";

defineProps<{
  item: ShipRatingDetails;
}>();

const emit = defineEmits(["toggleModal"]);

const ratingModal = ref();

const onClickOutside = (e: MouseEvent) => {
  if (
    ratingModal.value &&
    !ratingModal.value.modalBody.contains(e.target as Node)
  ) {
    emit("toggleModal");
  }
};

const classes =
  "my-2 flex grid-cols-2 flex-col sm:grid  sm:items-center sm:gap-8 ";

useHead({
  bodyAttrs: {
    class: "overflow-y-hidden",
  },
});
</script>
