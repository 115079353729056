<template>
  <div class="flex">
    <IconsBoat
      v-for="icon in parseInt(rating)"
      :key="icon"
      class="ml-0.5 size-4 lg:ml-1 lg:size-6"
      :class="classes"
    />
  </div>
</template>
<script lang="ts" setup>
interface Props {
  rating: string | number;
  color?: string;
}

const props = withDefaults(defineProps<Props>(), {
  color: "blue",
});

const mapping = {
  blue: "fill-g7-blue",
  orange: "fill-golden-bell",
};

const classes = mapping[props.color];
</script>
